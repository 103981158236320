import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AboutMe from './components/aboutMe';
import Home from './components/home';
import Navigation from './components/navigation';
import Gallery from './components/gallery';
import Todo from './components/todo';
import Banner from './components/banner';
import { Route, Switch } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
        <Navbar collapseOnSelect>
        <Container>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav>
                    <Nav.Link href='/'>Home</Nav.Link>
                    <Nav.Link eventKey="disabled" disabled>
                      Demos (soon!)
                    </Nav.Link>
                    {/* <Nav.Link href='/todo'>Todo</Nav.Link> */}
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        <Switch>                
          {/* <Route path='/todo' component={Todo}/> */}
          <Route path='/' component={Home}/>
        </Switch>
    </div>
  );
}

export default App;