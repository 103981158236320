import React, { useState } from 'react';
import { Card, CardGroup, Col, Container, ListGroup, Row, Toast, ToastContainer } from 'react-bootstrap';
import Accordion from './accordion'
import Banner from './banner'
import ProjectModal from './projectmodal'
import styles from '../styles.css'

var techExperience = (
  <>
  {/* START TECH EXPERIENCE */}
  <Row>
    <Col>
      <CardGroup>
        <Card>
          <Card.Img style={{ margin: 'auto', width: 'auto', paddingTop: '10px' }} src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/layers.svg'/>
          <Card.Body>
            <Card.Title>Production Level Experience</Card.Title>
            <Card.Text>
              Applications written/maintained with .NET Core, JavaScript, and TypeScript and popular client libraries within each.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">And a sprinkle of Python...</small>
          </Card.Footer>
        </Card>
        <Card>
        <Card.Img style={{ margin: 'auto', width: 'auto', paddingTop: '10px' }} src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/git.svg'/>
          <Card.Body>
            <Card.Title>Source Control</Card.Title>
            <Card.Text>
              Production CICD experience with Jenkins and repository experience with GitHub and BitBucket. 
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Getting better with git commands everyday!</small>
          </Card.Footer>
        </Card>
        <Card>
        <Card.Img style={{ margin: 'auto', width: 'auto', paddingTop: '10px' }} src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/database.svg'/>
          <Card.Body>
            <Card.Title>Database</Card.Title>
            <Card.Text>
              Years of experience with T-SQL databases. ORM experience with Entity Framework.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Working on my GraphQL!</small>
          </Card.Footer>
        </Card>
      </CardGroup>
    </Col>
  </Row>            
  <Row>
    <Col>
      <CardGroup>
        <Card>
          <Card.Img style={{ margin: 'auto', width: 'auto', paddingTop: '10px' }} src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/layout.svg'/>
          <Card.Body>
            <Card.Title>Front-end Frameworks</Card.Title>
            <Card.Text>
              Experience with Razor Pages, Blazor, and React.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Styled with Bootstrap, Material, etc.</small>
          </Card.Footer>
        </Card>
        <Card>
        <Card.Img style={{ margin: 'auto', width: 'auto', paddingTop: '10px' }} src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/amazon.svg'/>
          <Card.Body>
            <Card.Title>Amazon Web Services</Card.Title>
            <Card.Text>
              S3, IAM, EC2, RDS, CloudWatch, SQS, SNS, Lambda, Amplify and more!
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Pretty much everything on this site is AWS...</small>
          </Card.Footer>
        </Card>
        <Card>
        <Card.Img style={{ margin: 'auto', width: 'auto', paddingTop: '10px' }} src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/code.svg'/>
          <Card.Body>
            <Card.Title>REST API Services</Card.Title>
            <Card.Text>
              Integration of numerous third-party REST APIs. Development of internal REST services.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Amazon.com, Salesforce OCAPI, plus more!</small>
          </Card.Footer>
        </Card>
      </CardGroup>
    </Col>
  </Row>
  {/* END TECH EXPERIENCE */}
</>
);

var jobHistory = [
  {
    company: "Summit Sports",
    title: "Software Engineer",
    description: "Develop backend software solutions for eCommerce business needs.\n Interacting with product data, integrations, REST APIs, databases, cloud services, etc.\n Develop production software using numerous languages.\n Build and maintain internal web applications.\n Design faces of applications using common front-end libraries.\n Interface with relational databases.\n Daily interaction with all arms of the business.\n Application deployment.",
    date_text: "Current - Jul 2020",
    image_url: "https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/SummitSports.png"
  },
  {
    company: "Summit Sports",
    title: "Marketplace Manager / Junior Developer",
    description: "Oversee day to day operations of third party marketplaces. This includes sites such as Amazon, eBay and Walmart. Identifying reliable KPIs and building reporting. Sales, advertising, product information, or other types of data. Ensuring consistent and healthy metrics across all platforms as well as delivering a healthy revenue stream.",
    date_text: "Nov 2015 - Jul 2020",
    image_url: "https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/SummitSports.png"
  },
  {
    company: "Target Mobile",
    title: "Wireless Team Lead",
    description: "My regular goals were to have provide a great, friendly service whilst providing sales. I aimed to ensure guests had the products that were right for them individually. I ran the day to day operations at my location and held a fantastic reputation with customers, coworkers, and other partners the entire time.",
    date_text: "May 2014 - Dec 2015",
    image_url: "https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/Target.svg"
  },
  {
    company: "CPR Cell Phone Repair",
    title: "Sales Manager / Technician",
    description: "Opened and closed the shop, maintained stock, ensured customer satisfaction and performing quality repairs on any small electronic device.",
    date_text: "Jan 2014 - May 2014",
    image_url: "https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/CPR.png"
  },
  {
    company: "Target",
    title: "Team Member",
    description: "Provide Fast, Fun, and Friendly customer service while performing other tasks such as stocking, price changes, and doing inventory counts.",
    date_text: "Aug 2013 - Jan 2014",
    image_url: "https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/Target.svg"
  },
  {
    company: "McDonald's",
    title: "Crew Trainer",
    description: "I trained new employees to perform various jobs around the store. I also prepared food and worked in customer service. First jobs always teach a lot.",
    date_text: "Nov 2011 - Aug 2013",
    image_url: "https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/McDonalds.svg"
  }
];

var projects = (
  <>
  <Container>
    <Row>
      <Col>
      <ProjectModal title='phillblaul.com' buttonImage='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/phillblaul.png' subtitle='As the name might suggest...'/>
      </Col>
      <Col>
        <div className='appCard'>
          <img className='appCard-img' src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/construction.svg'/>
          <p className='appCard-p'>Second Project</p>
        </div>
      </Col>
      <Col>
        <div className='appCard'>
          <img className='appCard-img' src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/construction.svg'/>
          <p className='appCard-p'>Third Project</p>
        </div>
      </Col>
      <Col>
        <div className='appCard'>
          <img className='appCard-img' src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/construction.svg'/>
          <p className='appCard-p'>Fourth Project</p>
        </div>
      </Col>
      <Col>
        <div className='appCard'>
          <img className='appCard-img' src='https://s3.us-east-2.amazonaws.com/phillblaul.com/icons/construction.svg'/>
          <p className='appCard-p'>Fifth Project</p>
        </div>
      </Col>
    </Row>
  </Container>
  </>
);


var workExperience = (
  <>
    <ListGroup variant="flush">
      {jobHistory.map(({company, title, description, date_text, image_url}) => (
      <ListGroup.Item>
        <Container>
          <Row className='align-items-center'>
            <Col md={2} style={{whiteSpace:'pre-line'}}>
              <img width={150} height={150} src={image_url} />
            </Col>
            <Col md={8}>
              <h4>{company} - {title}</h4>
              <br/>
              <p>{description}</p>
            </Col>
            <Col>{date_text}</Col>
          </Row>
        </Container>
      </ListGroup.Item>
      ))}
    </ListGroup>
  </>
);

const Home = () => {
  const [show, setShow] = useState(true);
  const toggleShow = () => setShow(!show);
    return (
      <>
      <Container>
        <Banner></Banner>
        <Accordion title='Tech Experience' content={techExperience}></Accordion>
        <Accordion title='Work Experience' content={workExperience}></Accordion>
        <Accordion title='Projects (placeholder/WIP)' content={projects}></Accordion>
      </Container>
      <ToastContainer className="p-3" position='bottom-end'>
          <Toast show={show} onClose={toggleShow}>
            <Toast.Header closeButton={true}>
              <strong className="me-auto">⚠ Work In Progress ⚠</strong>
              <small>Aug 14</small>
            </Toast.Header>
            <Toast.Body>There maybe some broken stuff laying around.</Toast.Body>
          </Toast>
      </ToastContainer>
      </>
    );
}

export default Home;