import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styles from '../styles.css'

const bannerImage = "https://s3.us-east-2.amazonaws.com/phillblaul.com/MinersBeach.jpg"
const profileImage = "https://s3.us-east-2.amazonaws.com/phillblaul.com/Phill_hs_sq.jpg"
const name = "Phill Blaul"
const shortDesc  = "Full Stack Developer"

const Banner = () => {
    return (
      <>
      <Container>
        <Row className='align-items-center'>
          <img className='bannerImage' src={bannerImage}/>
          <img className='profileImage' src={profileImage}/>
          <div className='bannerText'>
            <h1>{name}</h1>
            <h3>{shortDesc}</h3>
          </div>
        </Row>
      </Container>
      </>
    );
}

export default Banner;