import React, { Component } from 'react';
import { Container, Row, Col, Pagination } from 'react-bootstrap';

class Gallery extends Component {
  render() {
    return (
        <Container>
          <Row>
            <Col xs={8}>Centered column hopefully</Col>
            <div className='gallery'>
              <div className='select'>
                <div className='image'>
                  <img src='https://s3.us-east-2.amazonaws.com/phillblaul.com/MinersBeach.jpg' alt=''/>
                </div>
                <div className='image'>
                  <img src='https://s3.us-east-2.amazonaws.com/phillblaul.com/MagnusCityBeachPark.jpg' alt=''/>
                </div>
                <div className='navigation'>
                  
                </div>
              </div>
            </div>
          </Row>
        </Container>
    );
  }
}

export default Gallery;