import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class About extends Component {
  render() {
    return (
      <section id="aboutMe">
        <Container>
          <Row>
            <Col>1 of 1</Col>
          </Row>
          <Row>
            <h1>Hello world!</h1>
          </Row>
        </Container>
      </section>
    );
  }
}

export default About;