import React, {useState} from 'react';
import { Modal, Button, Container, Row } from 'react-bootstrap';
import styles from '../styles.css'

const ProjectModal = ({title, buttonImage, subtitle}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
      <div className='appCard' onClick={handleShow}>
        <img className='appCard-img' src={buttonImage}/>
        <p className='appCard-p'>{title}</p>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Container>
            <Row className='projectmodal-title'>{title}</Row>
            <Row className='projectmodal-subtitle'>{subtitle}</Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>Written in JavaScript</Row>
            <Row>Developed on React Framework</Row>
            <Row>Source Control with GitHub (private repo for now sorry!)</Row>
            <Row>Hosted with AWS</Row><br/>
            <Row>TODOS:</Row>
            <Row>Add subdomain apps(Todo demo, Bnet demo, Riot demo)</Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    );
}

export default ProjectModal;