import React, { useState } from 'react';
import styles from '../styles.css'

const Accordion = ({title, content}) => {
  const [isActive, setIsActive] = useState(true);
    return (
      <>
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div>{title}{isActive}</div>
          <hr className='accordion-hr'></hr>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </div>
      </>
    );
}

export default Accordion;