import { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

class Navigation extends Component {
    render() {
        return (
            <>
                <Navbar collapseOnSelect>
                <Container>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav>
                            <Nav.Link href='/'>Home</Nav.Link>
                            <Nav.Link href='/' disabled>
                                Demos (soon!)
                            </Nav.Link>
                            {/* <Nav.Link href='/todo'>Todo</Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                </Navbar>
            </>
        );
    }
}

export default Navigation;  